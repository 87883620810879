import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: "https://7944495ba280454c80e73647c55b8cbe@o617827.ingest.sentry.io/5751258",
  release: "23a596722b98271765e2c58c21664d0040e84a28",
  environment: "production",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.25,
});
